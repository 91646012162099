<template>
	<div style="height: 100%;">
		<main-table tool>
			<div slot="table" class="df f1 fdc">
				<div class="df fww">
					<div class="p10 mr20 mb20 br5 miw100 cp" :style="'box-shadow: 0 0 10px 5px #f1f1f1; background-color: ' + (status.checked ? '#b7a06a' : '#e2d6ba')" v-for="(status, s) in status_tabs" :key="s" @click="toggleStatus(s)">
						<div class="df jcsb mb5">
							<div class="fs16 fw6 c3">{{status.count || 0}}</div>
						</div>
						<div class="fs14 c6">{{status.name}}</div>
					</div>
					<div class="mb10">
						<el-button type="primary" size="mini" @click="show_fillter = !show_fillter">{{show_fillter ? '收起' : '筛选'}}</el-button>
					</div>
				</div>
				<el-form :inline="true" :model="params" :size="theme.size" v-if="show_fillter">
					<el-form-item >
						<el-input v-model="params.keyword" placeholder="名称/描述" clearable></el-input>
					</el-form-item>
					<el-form-item v-if="!admin_user.shop_id">
						<el-select v-model="params.shop_id" placeholder="城市站点" filterable clearable>
							<el-option v-for="shop in shops" :key="shop.id" :label="shop.name" :value="shop.id"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item>
						<el-select v-model="params.employees" placeholder="请选择申请人" filterable  multiple collapse-tags>
							<el-option v-for="employee in employees" :key="employee.id" :label="employee.name" :value="employee.id"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item>
						<el-select v-model="params.nature" placeholder="性质" filterable  multiple collapse-tags>
							<el-option v-for="(nature, n) in fields.nature" :key="n" :label="nature" :value="n"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item>
						<el-select v-model="params.edu" placeholder="学历" filterable  multiple collapse-tags>
							<el-option v-for="(edu, e) in fields.edu" :key="e" :label="edu" :value="e"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item>
						<el-select v-model="params.urgency" placeholder="紧急" filterable  multiple collapse-tags>
							<el-option v-for="(urgency, u) in fields.urgency" :key="u" :label="urgency" :value="u"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item>
						<el-select v-model="params.experience" placeholder="经验" filterable  multiple collapse-tags>
							<el-option v-for="(experience, e) in fields.experience" :key="e" :label="experience" :value="e"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item>
						<el-select v-model="params.pay" placeholder="结算周期" filterable  multiple collapse-tags>
							<el-option v-for="(pay, p) in fields.pay" :key="p" :label="pay+'薪'" :value="p"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="getRecruitments({ ...params, page: 1})">查询</el-button>
					</el-form-item>
				</el-form>
				<div class="f1 pr">
					<el-table ref="position_table" class="bill scroll-wrapper pa" height="100%" :data="recruitments" :size="theme.size">
						<el-table-column label="站点" prop="shop.name" v-if="!admin_user.shop_id"></el-table-column>
						<el-table-column label="申请人" prop="employee.name" min-width="90"></el-table-column>
						<el-table-column label="名称" min-width="90">
							<el-link slot-scope="scope" type="primary" :disabled="!$utils.update($api.URI_POSITIONS_RECRUITMENTS)" @click="$refs['show_dialog'].open(scope.row)">{{scope.row.title}}</el-link>
						</el-table-column>
						<el-table-column label="性质" min-width="50" :formatter="({nature}) => { return fields.nature[nature]; }"></el-table-column>
						<el-table-column label="经验" min-width="70" :formatter="({experience}) => { return fields.experience[experience]; }"></el-table-column>
						<el-table-column label="学历" min-width="90" :formatter="({education}) => { return fields.edu[education]; }"></el-table-column>
						<el-table-column label="年龄" min-width="50" prop="age"></el-table-column>
						<el-table-column label="紧急" min-width="50" :formatter="({urgency}) => { return fields.urgency[urgency]; }"></el-table-column>
						<el-table-column label="薪资" min-width="130" :formatter="pay"></el-table-column>
						<el-table-column label="应聘/招聘" min-width="80" :formatter="({amount, candidates_count}) => { return candidates_count + ' / ' + amount; }"></el-table-column>
						<el-table-column label="最迟到岗" prop="latest_arrival" min-width="90" :formatter="({latest_arrival}) => { return $moment(latest_arrival).format('Y-MM-DD'); }"></el-table-column>
						<el-table-column label="描述" prop="description" min-width="120" show-overflow-tooltip></el-table-column>
						<template  v-if="theme.width >= 1450">
							<el-table-column label="更新时间" prop="updated_at" min-width="150"></el-table-column>
							<el-table-column label="创建时间" prop="created_at" min-width="150"></el-table-column>
						</template>
						<el-table-column label="状态" min-width="70">
							<el-link slot-scope="scope" type="primary" @click="$refs['show_dialog'].onStatus(scope.row)">{{statuses[scope.row.status]}}</el-link>
						</el-table-column>
						<el-table-column label="操作" width="60">
							<el-button slot="header" type="text" size="mini" @click="$refs['show_dialog'].open()" :disabled="!$utils.create($api.URI_POSITIONS_RECRUITMENTS)">添加</el-button>
							<template slot-scope="scope">
								<el-popconfirm title="确定恢复这个职位吗？" @confirm="handleRecruitment({action: 'restore', id: scope.row.id})" v-if="scope.row.deleted_at">
									<el-button slot="reference" type="text" :disabled="!$utils.restore($api.URI_POSITIONS_RECRUITMENTS)">恢复</el-button>
								</el-popconfirm>
								<el-popconfirm title="确定删除这个职位吗？" @confirm="onDelete(scope.row)" v-else>
									<el-button slot="reference" type="text" :disabled="!$utils.delete($api.URI_POSITIONS_RECRUITMENTS)">删除</el-button>
								</el-popconfirm>
							</template>
						</el-table-column>
					</el-table>
				</div>
			</div>
			<el-pagination
				slot="footer"
				:layout="$config.PAGE_LAYOUT"
				:page-sizes="$config.PAHE_SIZES"
				:page-size="params.perPage"
				:current-page="params.page"
				:total="params.total"
				@size-change="(v) => { getRecruitments({ ...params, page: 1, perPage: v }) }"
				@current-change="(v) => { getRecruitments({ ...params, page: v }) }"
				background>	
			</el-pagination>
		</main-table>
		<show-position ref="show_dialog" :fields="fields" @refresh="getRecruitments(params)"></show-position>
	</div>
</template>

<script>
	import { mapState } from 'vuex';
	import showPosition from './show';
	import mainTable from '@/components/main-table';

	export default {
		components: {
			mainTable,
			showPosition
		},
		computed: {
			...mapState(['theme', 'admin_user', 'models']),
			fields () {
				return this.models.recruitments || {};
			},
			statuses () {
				return this.fields.status || {};
			}
		},
		methods: {
			pay (r) {
				var title = '';
				if (r.pay_min && r.pay_max) {
					title = r.pay_min/100 + '-' + r.pay_max/100 + '元';	
				}
				if (!r.pay_min) {
					title = r.pay_max/100 + '元';	
				}
				if (!r.pay_max) {
					title = r.pay_min/100 + '元以上';	
				}
				if (!this.fields.pay) return title;
				return title + '/' + this.fields.pay[r.pay_type];
			},
			toggleStatus (s) {
				const { status } = this.params
				const index = status.indexOf(s);
				if (index > -1) {
					status.splice(index, 1);
					this.status_tabs[s].checked = false;
				} else {
					status.push(s);
					this.status_tabs[s].checked = true;
				}
				this.getRecruitments(this.params);
			},
			async getRecruitments (params, loading = false) {
				const res = await this.$http.get(this.$api.URI_POSITIONS_RECRUITMENTS, {params, headers: { loading }});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.recruitments = result.data;
				this.shops = result.shops;
				this.employees = result.employees;
				if (result.counts) {
					var status_tabs = {};
					const status = params.status || [];
					for (var s in this.statuses) {
						status_tabs[s] = {
							name: this.statuses[s],
							count: result.counts[s] || 0,
							checked: status.indexOf(s) >= 0 ? true : false
						};
					}
					this.status_tabs = status_tabs;
				}
				this.params = {
					...params,
					total: result.total,
					perPage: parseInt(result.per_page)
				}
			},
			async handleRecruitment (params) {
				const res = await this.$http.get(this.$api.URI_POSITIONS_RECRUITMENTS, {params, headers: {loading: true}});
				const { code, msg } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.getRecruitments(this.params);
				this.$message.success(msg);
			},
			onDelete (r) {
				if (r.employees_count) return this.$message.error(`还有 ${r.employees_count} 人在使用`);
				this.handleRecruitment({action: 'delete', id: r.id});
			}
		},
		data() {
			return {
				recruitments: [],
				status_tabs: [],
				employees: [],
				shops: [],
				show_fillter: false,
				params: {
					status: [],
					perPage: 10,
				}
			}
		},
		created () {
			this.getRecruitments(this.params, true);
		}
	};
</script>